import { api } from "../api";
import { AddPurchaseOrderDto, PurchaseOrderWithStatistics } from "./types";

const baseUrl = "purchaseOrders";

export const purchaseOrdersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPurchaseOrders: builder.query<PurchaseOrderWithStatistics[], number>({
      query: (inquiryId) => `${baseUrl}/list/${inquiryId}`,
      providesTags: () => [{ type: "PurchaseOrders", id: "list" }],
    }),
    addPurchaseOrder: builder.mutation<
      PurchaseOrderWithStatistics,
      AddPurchaseOrderDto
    >({
      query: (body) => ({ url: `/${baseUrl}`, method: "POST", body }),
      invalidatesTags: [{ type: "PurchaseOrders", id: "list" }],
    }),
    deletePurchaseOrder: builder.mutation<{ message: string }, number>({
      query: (id) => ({ url: `/${baseUrl}/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [
        { type: "PurchaseOrders", id: "list" },
        { type: "PurchaseOrders", id },
      ],
    }),
  }),
});

export const {
  useGetPurchaseOrdersQuery,
  useAddPurchaseOrderMutation,
  useDeletePurchaseOrderMutation,
} = purchaseOrdersApi;
