import React from "react";
import { Box } from "@mui/material";
import { Heading } from "./Heading";
import { Budget, BudgetProps } from "../../../../../components/Budget/Budget";

type ListProps = {
  departmentName: string;
  status: React.ReactNode;
  actions: React.ReactNode;
  totalProducts: number;
} & BudgetProps;

export const List = ({
  departmentName,
  status,
  spent,
  currency,
  assigned,
  productsWithoutPrice,
  totalProducts,
  actions,
}: ListProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Heading departmentName={departmentName} />
      <Box
        sx={{
          marginLeft: "20px",
        }}
      >
        {status}
      </Box>
      <Budget
        spent={spent}
        assigned={assigned}
        currency={currency}
        productsWithoutPrice={productsWithoutPrice}
        totalProducts={totalProducts}
      />
      <Box
        sx={{
          marginLeft: "auto",
          display: "flex",
          gap: "20px",
        }}
      >
        {actions}
      </Box>
    </Box>
  );
};
