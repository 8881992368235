import React from "react";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Toolbar,
} from "@mui/material";
import {
  Abc,
  ChevronLeft,
  InventoryOutlined,
  Mail,
  People,
  QueryStats,
  ShoppingCart,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { usePermissions } from "../../utils/usePermissions";
import { useIsSchoolTeam } from "../../utils/useIsSchoolTeam";
import { useSelector } from "../../store";
import {
  useCanViewSchoolInquiries,
  useCanViewShop,
} from "../../permissions/commonChecks";
import { selectSelfRole } from "../../slices/auth/selectors";

type NavigationDrawerProps = {
  open: boolean;
  closeDrawer: () => void;
};

export const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
  open,
  closeDrawer,
}) => {
  const { t: pagesTranslation } = useTranslation("pages");
  const can = usePermissions();
  const isSchoolTeam = useIsSchoolTeam();
  const rootMatcher = useRouteMatch({ path: "/", exact: true });
  const pathMatcher = useRouteMatch<{ path: string }>("/:path");
  const path = pathMatcher?.params?.path;
  const selfRole = useSelector(selectSelfRole);

  const allowedToViewSchoolInquiries = useCanViewSchoolInquiries();
  const allowedToViewShop = useCanViewShop();

  return (
    <StyledDrawer variant="persistent" open={open}>
      <CloseButtonBar>
        <IconButton onClick={closeDrawer}>
          <ChevronLeft />
        </IconButton>
      </CloseButtonBar>
      <Divider />
      <List>
        {can("getInquiries") && (
          <ListItemButton
            component={Link}
            to="/inquiries"
            selected={path === "inquiries" || !!rootMatcher}
          >
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <ListItemText primary={pagesTranslation("inquiries.title")} />
          </ListItemButton>
        )}
        {can("getItems") && selfRole !== "orderManager" && (
          <ListItemButton
            component={Link}
            to="/newitems"
            selected={path === "newitems"}
          >
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <ListItemText primary={pagesTranslation("newitems.title")} />
          </ListItemButton>
        )}
        {can("getItems") && selfRole !== "orderManager" && (
          <ListItemButton
            component={Link}
            to="/new-search-request-items"
            selected={path === "new-search-request-items"}
          >
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <ListItemText
              primary={pagesTranslation(
                "newSearchRequestItems.navigationTitle"
              )}
            />
          </ListItemButton>
        )}
        {can("getProducts") &&
          selfRole !== "headOfDepartment" &&
          selfRole !== "teacher" && (
            <ListItemButton
              component={Link}
              to="/products"
              selected={path === "products"}
            >
              <ListItemIcon>
                <Abc />
              </ListItemIcon>
              <ListItemText primary={pagesTranslation("products.title")} />
            </ListItemButton>
          )}
        {can("viewInventory") && (
          <ListItemButton
            component={Link}
            to="/inventory"
            selected={path === "inventory"}
          >
            <ListItemIcon>
              <InventoryOutlined />
            </ListItemIcon>
            <ListItemText primary={pagesTranslation("inventory.title")} />
          </ListItemButton>
        )}
        {(can("viewUsers") || can("viewUsersMenu")) &&
          selfRole !== "principal" &&
          selfRole !== "headOfDepartment" && (
            <ListItemButton
              component={Link}
              to="/users"
              selected={path === "users"}
            >
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <ListItemText primary={pagesTranslation("users.title")} />
            </ListItemButton>
          )}
        {(can("viewUsers") || can("viewUsersMenu")) &&
          (selfRole === "principal" || selfRole === "headOfDepartment") && (
            <ListItemButton
              component={Link}
              to="/users"
              selected={path === "users"}
            >
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <ListItemText primary={pagesTranslation("users.title")} />
            </ListItemButton>
          )}
        {can("getSearches") && (
          <ListItemButton
            component={Link}
            to="/searches"
            selected={path === "searches"}
          >
            <ListItemIcon>
              <QueryStats />
            </ListItemIcon>
            <ListItemText primary={pagesTranslation("searches.title")} />
          </ListItemButton>
        )}
        {(allowedToViewSchoolInquiries || allowedToViewShop) && <Divider />}
        {allowedToViewSchoolInquiries && (
          <ListItemButton
            component={Link}
            to="/schoolinquiries"
            selected={path === "schoolinquiries"}
            target="_blank"
          >
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <ListItemText
              primary={pagesTranslation(
                isSchoolTeam
                  ? "schoolInquiries.schoolTeamTitle"
                  : "schoolInquiries.title"
              )}
            />
          </ListItemButton>
        )}
        {allowedToViewShop && (
          <ListItemButton
            component={Link}
            to={{
              pathname: "/shop",
              state: {
                restoreParams: true,
                scrollToTop: true,
              },
            }}
            selected={path === "shop"}
            target="_blank"
          >
            <ListItemIcon>
              <ShoppingCart />
            </ListItemIcon>
            <ListItemText primary={pagesTranslation("shopCatalog.title")} />
          </ListItemButton>
        )}
      </List>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)<{ open: boolean }>(({ open }) => ({
  width: open ? "240px" : 0,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: "240px",
  },
}));

const CloseButtonBar = styled(Toolbar)({
  display: "flex",
  flexDirection: "row-reverse",
});
