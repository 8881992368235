import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import { Link as MaterialLink } from "@mui/material";
import { Link } from "react-router-dom";
import { baseUrl } from "../baseUrl";
import { GetNewSearchRequestItemsResponseDto } from "../../../slices/new-search-request-items/types";

export const useColumns = () => {
  const { t } = useTranslation("pages");

  return useMemo<
    GridColDef<GetNewSearchRequestItemsResponseDto["data"][number]>[]
  >(() => {
    return [
      {
        field: "isApproved",
        headerName: t("newSearchRequestItems.columns.isApproved"),
        width: 40,
        type: "boolean",
        valueGetter: ({ row: { isApproved } }) => isApproved,
      },
      {
        field: "supplier",
        headerName: t("newSearchRequestItems.columns.supplier"),
        flex: 0.1,
        renderCell: ({ row: { supplier } }) =>
          supplier ?? t("na", { ns: "common" }),
      },
      {
        field: "supplierProductCode",
        headerName: t("newSearchRequestItems.columns.supplierProductCode"),
        flex: 0.15,
        renderCell: ({ row: { supplierProductCode } }) =>
          supplierProductCode ?? t("na", { ns: "common" }),
      },
      {
        field: "name",
        headerName: t("newSearchRequestItems.columns.name"),
        flex: 0.2,
        renderCell: ({ row: { id, name } }) => (
          <MaterialLink component={Link} to={`${baseUrl}/${id}`}>
            {name}
          </MaterialLink>
        ),
      },
      {
        field: "quantity",
        headerName: t("newSearchRequestItems.columns.quantity"),
        flex: 0.05,
        type: "number",
        valueGetter: ({ row: { quantity } }) => quantity,
      },
      {
        field: "schoolName",
        headerName: t("newSearchRequestItems.columns.school"),
        flex: 0.15,
        valueGetter: ({ row: { schoolName } }) => schoolName,
      },
      {
        field: "inquiryName",
        headerName: t("newSearchRequestItems.columns.inquiry"),
        flex: 0.1,
        renderCell: ({ row: { inquiryName } }) => inquiryName,
      },
      {
        field: "departmentName",
        headerName: t("newSearchRequestItems.columns.department"),
        flex: 0.1,
        renderCell: ({ row: { departmentName } }) => departmentName,
      },
      {
        field: "teacherName",
        headerName: t("newSearchRequestItems.columns.teacher"),
        flex: 0.1,
        renderCell: ({ row: { teacherName } }) => teacherName,
      },
    ];
  }, [t]);
};
