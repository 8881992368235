export default {
  header: {
    changePassword: "Change Password",
    logout: "Log Out",
  },

  toolbar: {
    search: "Search",
  },

  wysiwygField: {
    formattingButtons: "Formatting buttons",

    formatting: {
      bold: "Bold",
      italic: "Italic",
      strike: "Strike",
    },

    table: {
      createTable: "Create a table",
      tableControls: "Table Controls",

      addRowAbove: "Add Row Above",
      addRowBelow: "Add Row Below",
      addColumnAfter: "Add Column After",
      addColumnBefore: "Add Column Before",
      toggleHeaderCell: "Toggle Cell Header",
      removeColumn: "Delete Selected Column",
      removeRow: "Delete Selected Row",
      removeTable: "Delete Table",
    },
  },

  shortProductInformation: {
    na: "N/A",
  },

  controlledImageField: {
    choose: "Choose an image",
    image: "Image",
  },

  cantFindProduct: {
    title: "Can’t find necessary product?",
    text: "Describe what you need so that our administrators can offer you something from our assortment.",
    placeholder: "e.g. We need a big green table for our students",
    add: "Add",
    attachment: "Image you've attached",
  },

  list: {
    noRows: "No rows",
  },

  budget: {
    spentBudget: "Spent budget",
    assignedBudget: "Assigned budget",
    productsWithoutPrice_one: "Product Without Price",
    productsWithoutPrice_other: "Products Without Price",
    totalProducts: "Products",
  },

  fileField: {
    noFile: "No file picked",
  },
};
