import {
  AddItemRequestDto,
  AssignItemsMainOrderRequestDto,
  AssignItemsPurchaseOrderRequestDto,
  AssignProductRequestDto,
  ChangeIncludedInCommercialOfferRequestDto,
  ChangeIncludedInCommercialOfferResponseDto,
  ChangeItemInquiryStatusRequestDto,
  ChangeItemOrderStatusRequestDto,
  DeleteItemRequestDto,
  EditItemRequestDto,
  GetItemResponseDto,
  GetMatchingProductsListResponseDto,
  GetNewItemsRequest,
  GetNewItemsResponse,
  GetNextNewItemResponseDto,
  GetNextNewItemsRequestDto,
  Item,
  ItemRowsResponse,
  ItemServerCrudResponse,
  ItemsRowsRequest,
  SubmitRequestDto,
  UnassignProductRequestDto,
} from "./types";
import { DEFAULT_SORT_MODEL_LAST_ON_TOP } from "../../consts";
import { AssignMessageResponseDto, MessageResponseDto } from "../../types";
import { api } from "../api";
import { parseSortModel } from "../../utils/parseSortModel";

type InvalidateCacheIdentifiers = {
  inquiryId: number;
};

const baseUrl = "items";

export const itemsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getItems: builder.query<ItemServerCrudResponse, ItemsRowsRequest>({
      query: ({
        departmentInquiryId,
        search = "",
        page,
        pageSize,
        sortModel,
        inquiryStatusFilter,
        orderStatusFilter,
        purchaseOrderId,
        mainOrderId,
      }) => {
        const searchParams = new URLSearchParams({
          ...parseSortModel(sortModel),
          page: page.toString(),
          pageSize: pageSize.toString(),
          search: search ?? "",
          inquiryStatuses:
            inquiryStatusFilter && inquiryStatusFilter.length > 0
              ? inquiryStatusFilter
                  .reduce((prev, curr) => `${prev}_${curr}`, "")
                  .substring(1)
              : "",
          orderStatuses:
            orderStatusFilter && orderStatusFilter.length > 0
              ? orderStatusFilter
                  .reduce((prev, curr) => `${prev}_${curr}`, "")
                  .substring(1)
              : "",
        });

        if (departmentInquiryId) {
          searchParams.append("parentId", departmentInquiryId.toString());
        }

        if (purchaseOrderId) {
          searchParams.append("purchaseOrderId", purchaseOrderId.toString());
        }

        if (mainOrderId) {
          searchParams.append("mainOrderId", mainOrderId.toString());
        }

        return `${baseUrl}?${searchParams.toString()}`;
      },
      transformResponse: (response: ItemRowsResponse) => ({
        data: response.data.map((item) => ({
          ...item,
          departmentInquiryId: item.departmentInquiry?.id,
          supplier: item.supplier?.name,
        })),
        inquiryName: response.inquiryName,
        parentName: response.parentName,
        rowCount: response.rowCount,
        inquiryStatistics: response.inquiryStatistics,
        orderStatistics: response.orderStatistics,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Item" as const,
                id,
              })),
              { type: "Item", id: "list", rowCount: result.rowCount },
            ]
          : [{ type: "Item", id: "list", rowCount: 0 }],
    }),
    getNewItems: builder.query<GetNewItemsResponse, GetNewItemsRequest>({
      query: ({ search = "", page, pageSize, sortModel }) => {
        const searchParams = new URLSearchParams({
          page: page.toString(),
          pageSize: pageSize.toString(),
          search: search ?? "",
          sortField:
            sortModel && sortModel.length > 0
              ? sortModel[0].field
              : DEFAULT_SORT_MODEL_LAST_ON_TOP[0].field,
          sort:
            sortModel && sortModel.length > 0
              ? (sortModel[0].sort as string)
              : (DEFAULT_SORT_MODEL_LAST_ON_TOP[0].sort as string),
        });

        return `${baseUrl}/new?${searchParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Item" as const,
                id,
              })),
              { type: "Item", id: "list", rowCount: result.rowCount },
            ]
          : [{ type: "Item", id: "list", rowCount: 0 }],
    }),
    getItem: builder.query<GetItemResponseDto, number>({
      query: (id) => `${baseUrl}/${id}`,
      providesTags: (result, error, id) => [{ type: "Item", id }],
    }),
    getNextNewItem: builder.query<
      GetNextNewItemResponseDto,
      GetNextNewItemsRequestDto
    >({
      query: ({ id, isInInquiry }) => {
        return `${baseUrl}/nextNew/${id}?isInInquiry=${isInInquiry || ""}`;
      },
      providesTags: () => [{ type: "NextNewItemsList" }],
    }),
    addItem: builder.mutation<
      Item,
      AddItemRequestDto & InvalidateCacheIdentifiers
    >({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ inquiryId, ...body }) => ({
        url: baseUrl,
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, _error, { inquiryId }) => [
        { type: "Item", id: "list" },
        { type: "Inquiry", id: "list" },
        { type: "DetailedInquiry", id: inquiryId },
        { type: "NextNewItemsList" },
      ],
    }),
    editItem: builder.mutation<Item, EditItemRequestDto>({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Item", id: "list" },
        { type: "Item", id },
      ],
    }),
    changeItemInquiryStatus: builder.mutation<
      MessageResponseDto,
      ChangeItemInquiryStatusRequestDto & InvalidateCacheIdentifiers
    >({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ id, inquiryId, ...body }) => ({
        url: `${baseUrl}/changeInquiryStatus/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id, inquiryId }) => [
        { type: "Item", id: "list" },
        { type: "Item", id },
        { type: "Inquiry", id: "list" },
        { type: "DetailedInquiry", id: inquiryId },
        { type: "NextNewItemsList" },
      ],
    }),
    changeItemOrderStatus: builder.mutation<
      MessageResponseDto,
      ChangeItemOrderStatusRequestDto & InvalidateCacheIdentifiers
    >({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ id, inquiryId, ...body }) => ({
        url: `${baseUrl}/changeOrderStatus/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id, inquiryId }) => [
        { type: "Item", id: "list" },
        { type: "Item", id },
        { type: "Inquiry", id: "list" },
        { type: "DetailedInquiry", id: inquiryId },
      ],
    }),
    getMatchedProductsList: builder.query<
      GetMatchingProductsListResponseDto,
      number
    >({
      query: (id) => `${baseUrl}/matchedProductsLists/${id}`,
      providesTags: (result, error, id) => [
        { type: "MatchedProductsList", id },
      ],
    }),
    assignProduct: builder.mutation<
      AssignMessageResponseDto,
      AssignProductRequestDto & InvalidateCacheIdentifiers
    >({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ itemId, inquiryId, ...body }) => ({
        url: `${baseUrl}/assign/${itemId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { itemId, productId, inquiryId }) => [
        { type: "Item", id: itemId },
        { type: "Item", id: "list" },
        { type: "MatchedProductsList", id: itemId },
        { type: "Product", id: productId },
        { type: "Inquiry", id: "list" },
        { type: "DetailedInquiry", id: inquiryId },
        { type: "PurchaseOrders", id: "list" },
        { type: "NextNewItemsList" },
      ],
    }),
    unassignProduct: builder.mutation<
      MessageResponseDto,
      UnassignProductRequestDto & InvalidateCacheIdentifiers
    >({
      query: ({ id }) => ({
        url: `${baseUrl}/unassign/${id}`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, { id, inquiryId }) => [
        { type: "Item", id },
        { type: "Item", id: "list" },
        { type: "Inquiry", id: "list" },
        { type: "DetailedInquiry", id: inquiryId },
        { type: "NextNewItemsList" },
      ],
    }),
    assignItemsPurchaseOrder: builder.mutation<
      MessageResponseDto,
      AssignItemsPurchaseOrderRequestDto & InvalidateCacheIdentifiers
    >({
      query: ({ itemsIds, purchaseOrderId }) => ({
        url: `${baseUrl}/assignPurchaseOrder`,
        method: "PATCH",
        body: { itemsIds, purchaseOrderId },
      }),
      invalidatesTags: (result, error, { itemsIds, inquiryId }) => [
        ...itemsIds.map((id) => ({ type: "Item", id } as const)),
        { type: "Item", id: "list" },
        { type: "Inquiry", id: "list" },
        { type: "DetailedInquiry", id: inquiryId },
        { type: "PurchaseOrders", id: "list" },
      ],
    }),
    assignMainOrder: builder.mutation<
      MessageResponseDto,
      AssignItemsMainOrderRequestDto & InvalidateCacheIdentifiers
    >({
      query: ({ itemsIds, mainOrderId }) => ({
        url: `${baseUrl}/assignMainOrder`,
        method: "PATCH",
        body: { itemsIds, mainOrderId },
      }),
      invalidatesTags: (result, error, { itemsIds, inquiryId }) => [
        ...itemsIds.map((id) => ({ type: "Item", id } as const)),
        { type: "Item", id: "list" },
        { type: "Inquiry", id: "list" },
        { type: "DetailedInquiry", id: inquiryId },
        { type: "MainOrders", id: "list" },
      ],
    }),
    deleteItem: builder.mutation<
      MessageResponseDto,
      DeleteItemRequestDto & InvalidateCacheIdentifiers
    >({
      query: ({ id }) => ({ url: `${baseUrl}/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, { id, inquiryId }) => [
        { type: "Item", id: "list" },
        { type: "Item", id },
        { type: "Inquiry", id: "list" },
        { type: "DetailedInquiry", id: inquiryId },
        { type: "MainOrders", id: "list" },
        { type: "PurchaseOrders", id: "list" },
        { type: "NextNewItemsList" },
      ],
    }),
    submit: builder.mutation<
      MessageResponseDto,
      SubmitRequestDto & InvalidateCacheIdentifiers
    >({
      query: ({ itemsIds }) => ({
        url: `${baseUrl}/submit`,
        method: "PATCH",
        body: { itemsIds },
      }),
      invalidatesTags: (result, error, { itemsIds, inquiryId }) => [
        ...itemsIds.map((id) => ({ type: "Item", id } as const)),
        { type: "Item", id: "list" },
        { type: "Inquiry", id: "list" },
        { type: "DetailedInquiry", id: inquiryId },
        { type: "PurchaseOrders", id: "list" },
      ],
    }),
    changeIncludedInCommercialOffer: builder.mutation<
      ChangeIncludedInCommercialOfferResponseDto,
      ChangeIncludedInCommercialOfferRequestDto & InvalidateCacheIdentifiers
    >({
      query: ({ itemsIds, includedInCommercialOffer }) => ({
        url: `${baseUrl}/change-included-in-commercial-offer-flag`,
        method: "PATCH",
        body: {
          itemsIds,
          includedInCommercialOffer,
        },
      }),
      invalidatesTags: (result, error, { itemsIds, inquiryId }) => [
        ...itemsIds.map((id) => ({ type: "Item", id } as const)),
        { type: "Item", id: "list" },
        { type: "Inquiry", id: "list" },
        { type: "DetailedInquiry", id: inquiryId },
        { type: "PurchaseOrders", id: "list" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetItemsQuery,
  useGetNewItemsQuery,
  useGetItemQuery,
  useGetNextNewItemQuery,
  useAddItemMutation,
  useEditItemMutation,
  useChangeItemInquiryStatusMutation,
  useChangeItemOrderStatusMutation,
  useGetMatchedProductsListQuery,
  useAssignProductMutation,
  useUnassignProductMutation,
  useAssignItemsPurchaseOrderMutation,
  useAssignMainOrderMutation,
  useDeleteItemMutation,
  useSubmitMutation,
  useChangeIncludedInCommercialOfferMutation,
} = itemsApi;
