import React from "react";
import { createSvgIcon } from "@mui/material";

export const Heart = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 18.115l-1.07-0.97C7.1 14.125 5 12.1 5 9.585 5 7.5 6.62 6 8.67 6c1.28 0 2.51 0.6 3.33 1.55C12.82 6.6 14.05 6 15.33 6 17.38 6 19 7.62 19 9.585c0 2.515-2.1 4.54-5.43 7.545L11.5 18.115z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "Heart"
);
