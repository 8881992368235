import React from "react";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import { useGetSchoolAssignmentQuery } from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/query";
import { Title } from "../Title/Title";
import { List } from "../List/List";
import { Status } from "./Status";
import { ListSkeleton } from "../List/ListSkeleton";
import { TitleSkeleton } from "../Title/TitleSkeleton";
import { SendToHead } from "../actions/assignment/SendToHead";

export const HeaderForTeacher = () => {
  const { inquiryId, departmentInquiryId, assignmentId } =
    useSchoolInquiryItemsTools();

  const { data: assignment, isLoading: isAssignmentLoading } =
    useGetSchoolAssignmentQuery(assignmentId ? { assignmentId } : skipToken);

  const isLoading = isAssignmentLoading || !assignment;

  if (!inquiryId || !departmentInquiryId || !assignmentId) {
    return null;
  }

  return (
    <>
      {isLoading ? (
        <TitleSkeleton />
      ) : (
        <Title
          inquiryName={assignment.departmentName}
          schoolName={assignment.schoolName}
        />
      )}
      {isLoading ? (
        <ListSkeleton />
      ) : (
        <List
          departmentName={assignment.departmentName}
          status={
            <Status
              schoolStatus={assignment.schoolStatus}
              schoolTitles={assignment.schoolTitles}
            />
          }
          spent={assignment.budgetSpent}
          assigned={assignment.budgetAssigned}
          currency={assignment.budgetCurrency}
          productsWithoutPrice={assignment.productsWithoutPrice}
          totalProducts={assignment.totalProducts}
          actions={
            <SendToHead
              {...{
                inquiryId,
                departmentInquiryId,
                assignmentId,
              }}
              disabled={!assignment.possibleActions.includes("sendToHead")}
              displayHelp={assignment.displayHelpMessageForActions.includes(
                "sendToHead"
              )}
              headOfDepartmentTitle={assignment.schoolTitles.headOfDepartment}
            />
          }
        />
      )}
    </>
  );
};
