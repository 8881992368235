import React, { useEffect, useMemo } from "react";
import { ContainerBase } from "../../components/layout/ContainerBase";
import { Box, styled, Typography } from "@mui/material";
import { useGetShopProductQuery } from "../../slices/shop/api";
import { skipToken } from "@reduxjs/toolkit/query";
import { ImageList } from "../../components/ImageList";
import { PriceCurrency } from "../../components/PriceCurrency";
import { Add } from "./Add";
import { Info } from "./Info";
import { useTranslation } from "react-i18next";
import { Description } from "./Description";
import { useShopDetailsUrlTools } from "./useShopDetailsUrlTools";
import { CantFindProduct } from "../../components/CantFindProduct/CantFindProduct";
import { QuantityInCart } from "./QuantityInCart";
import { useInCart } from "../../slices/shop/hooks";
import { EnhancedSkeleton } from "../../components/EnhancedSkeleton";
import { colors } from "../../theme/astra/colors";
import { Back } from "../../components/layout/Back";
import { AddToWishlist } from "./AddToWishlist";

export const ShopDetails = () => {
  const { t } = useTranslation("pages");

  const { productId } = useShopDetailsUrlTools();
  const quantityInCart = useInCart(productId);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { data: product, isLoading: isProductLoading } = useGetShopProductQuery(
    productId ?? skipToken
  );

  const na = t("na", { ns: "common" });

  const imageList = useMemo(() => {
    if (isProductLoading || !product) {
      return (
        <EnhancedSkeleton
          variant="rectangular"
          sx={{
            width: "668px",
            height: "552px",
          }}
        />
      );
    }

    return <ImageList images={product.images} name={product.name} />;
  }, [isProductLoading, product]);

  const name = useMemo(() => {
    if (isProductLoading || !product) {
      return <EnhancedSkeleton variant="text" width={[50, 100, "%"]} />;
    }

    return product.name ?? na;
  }, [isProductLoading, na, product]);

  const code = useMemo(() => {
    if (isProductLoading || !product) {
      return <EnhancedSkeleton variant="text" width={[20, 50, "%"]} />;
    }

    return `${t("shopDetails.productCode")}: ${
      product.supplierProductCode ?? na
    }`;
  }, [isProductLoading, na, product, t]);

  const priceCurrency = useMemo(() => {
    if (isProductLoading || !product) {
      return (
        <EnhancedSkeleton
          variant="rectangular"
          sx={{ marginTop: "52px", fontSize: "13px", lineHeight: "24px" }}
        />
      );
    }

    return (
      <PriceCurrency
        price={product.retailPrice}
        currency={product.retailCurrency?.code}
        sx={{ marginTop: "52px", color: "primary.dark" }}
        priceProps={{ sx: { fontSize: "32px" } }}
        currencyProps={{ sx: { fontSize: "16px", lineHeight: "16px" } }}
      />
    );
  }, [isProductLoading, product]);

  const description = useMemo(() => {
    if (isProductLoading || !product) {
      return (
        <>
          <DescriptionSkeleton
            variant="text"
            sx={{ marginTop: "32px" }}
            width={[90, 100, "%"]}
          />
          <DescriptionSkeleton variant="text" width={[80, 100, "%"]} />
          <DescriptionSkeleton variant="text" width={[20, 50, "%"]} />
        </>
      );
    }

    return <Description description={product.description} />;
  }, [isProductLoading, product]);

  const info = useMemo(() => {
    if (isProductLoading || !product) {
      return (
        <EnhancedSkeleton
          variant="text"
          width="250px"
          height="54px"
          sx={{ marginTop: "47px", fontSize: "18px", lineHeight: "24px" }}
        />
      );
    }

    return <Info product={product} />;
  }, [isProductLoading, product]);

  if (
    !isProductLoading &&
    (!product ||
      !product.retailPrice ||
      !product.retailCurrency ||
      !product.images ||
      product.images.length < 1)
  ) {
    return (
      <ContainerBase
        sx={{
          paddingTop: "30px",
        }}
        maxWidth="sm"
      >
        <Back>{t("shopDetails.back")}</Back>
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            minHeight: "calc(100vh - 250px)",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "500",
              color: colors.darkBlue,
            }}
          >
            {t("shopDetails.productNotFound")}
          </Typography>
        </Box>
      </ContainerBase>
    );
  }

  return (
    <ContainerBase
      sx={{ paddingTop: "30px", paddingBottom: "195px" }}
      maxWidth="sm"
    >
      <Back>{t("shopDetails.back")}</Back>
      <Box sx={{ display: "flex", gap: "24px", marginTop: "16px" }}>
        <Box sx={{ flexGrow: 0, flexShrink: 0, position: "relative" }}>
          <QuantityInCart quantity={quantityInCart} />
          {imageList}
        </Box>
        <Box sx={{ flexGrow: 1, flexShrink: 1 }}>
          <Typography
            component="h1"
            sx={{
              fontSize: "24px",
              lineHeight: "20px",
              fontWeight: "500",
              color: "primary.dark",
              marginTop: "16px",
            }}
            dir="auto"
          >
            {name}
          </Typography>
          <Typography
            component="span"
            sx={{
              marginTop: "4px",
              fontSize: "14px",
              lineHeight: "24px",
              color: "secondary.main",
            }}
          >
            {code}
          </Typography>
          {priceCurrency}
          <Box
            sx={{
              display: "flex",
              gap: "24px",
              marginTop: "24px",
            }}
          >
            <Add />
            <AddToWishlist
              productId={productId}
              isInWishlist={product?.isInWishlist}
            />
          </Box>
          {description}
          {info}
        </Box>
      </Box>
      <CantFindProduct sx={{ marginTop: "126px", alignSelf: "center" }} />
    </ContainerBase>
  );
};

const DescriptionSkeleton = styled(EnhancedSkeleton)({
  fontSize: "14px",
  lineHeight: "24px",
});
