import React from "react";
import {
  alpha,
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  useTheme,
} from "@mui/material";
import { matchProp } from "./matchProp";
import { IconButtonTypeMap } from "@mui/material/IconButton/IconButton";
import { Circular } from "../spinners/Circular";

export type IconButtonVariant = "contained" | "outlined" | "transparent";

export type IconButtonColor = "primary" | "secondary";

export type IconButtonProps<
  D extends React.ElementType = IconButtonTypeMap["defaultComponent"],
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {}
> = MuiIconButtonProps<D, P> & {
  variant?: IconButtonVariant;
  color?: IconButtonColor;
  loading?: boolean;
};

export const IconButton = <
  D extends React.ElementType = IconButtonTypeMap["defaultComponent"],
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {}
>({
  variant = "contained",
  size = "medium",
  color = "primary",
  loading,
  sx,
  children,
  ...props
}: IconButtonProps<D, P>) => {
  const theme = useTheme();

  return (
    <MuiIconButton
      sx={{
        ...matchProp(variant, {
          contained: {
            borderRadius: "4px",

            ...matchProp(color, {
              primary: {
                backgroundColor: "primary.main",
                color: "primary.contrastText",

                "&:hover": {
                  backgroundColor: "primary.main",
                },
              },
              secondary: {
                backgroundColor: "secondary.light",
                color: "primary.contrastText",

                "&:hover": {
                  backgroundColor: "secondary.light",
                },
              },
            }),
          },
          outlined: {
            borderRadius: "4px",
            borderWidth: "1px",
            borderStyle: "solid",

            ...matchProp(color, {
              primary: {
                borderColor: "primary.main",
                backgroundColor: alpha(theme.palette.primary.main, 0.05),
                color: theme.palette.primary.main,

                "&:hover": {
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                },

                "&:active": {
                  backgroundColor: alpha(theme.palette.primary.main, 0.25),
                },
              },
              secondary: {
                borderColor: "secondary.light",
                color: theme.palette.primary.main,

                "&:hover": {
                  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
                },

                "&:active": {
                  backgroundColor: alpha(theme.palette.secondary.main, 0.25),
                },
              },
            }),
          },
          transparent: {},
        }),

        ...matchProp(size, {
          small: {
            width: "40px",
            height: "38px",
          },
          medium: {
            width: "40px",
            height: "40px",
          },
          large: {
            width: "64px",
            height: "64px",
          },
        }),

        svg: {
          width: "24px",
          height: "24px",
        },
        ...sx,
      }}
      {...props}
    >
      {loading ? <Circular size={size ?? 20} /> : children}
    </MuiIconButton>
  );
};
