import React, { useCallback } from "react";
import {
  useAddWishlistRecordMutation,
  useRemoveWishlistRecordMutation,
} from "../../../../slices/wishlistRecords/api";
import { colors } from "../../../../theme/astra/colors";
import { Heart } from "../../../../components/astraIcons/Heart";
import { IconButton } from "../../../../components/astraComponents/IconButton";
import { useSelector } from "../../../../store";
import { selectSelfRole } from "../../../../slices/auth/selectors";
import { GetShopProductsRequestDto } from "../../../../slices/shop/types";

type AddToWishlistProps = {
  productId: number;
  isInWishlist: boolean;
  params: GetShopProductsRequestDto;
};

export const AddToWishlist = ({
  productId,
  isInWishlist,
  params,
}: AddToWishlistProps) => {
  const selfRole = useSelector(selectSelfRole);

  const [addWishlistRecord, { isLoading: isWishlistRecordAdding }] =
    useAddWishlistRecordMutation();
  const [removeWishlistRecord, { isLoading: isWishlistRecordRemoving }] =
    useRemoveWishlistRecordMutation();

  const clickHandler = useCallback(() => {
    if (isInWishlist) {
      removeWishlistRecord({
        productId,
        params,
      });

      return;
    }

    addWishlistRecord({
      productId,
      params,
    });
  }, [
    addWishlistRecord,
    isInWishlist,
    params,
    productId,
    removeWishlistRecord,
  ]);

  if (
    !selfRole ||
    !["principal", "headOfDepartment", "teacher"].includes(selfRole)
  ) {
    return null;
  }

  return (
    <IconButton
      id={`product-${productId}-controls-wishlist`}
      variant="transparent"
      sx={{
        color: isInWishlist ? colors.red : colors.blue,
        backgroundColor: colors.white,
        position: "absolute",
        zIndex: 2,
        top: "12px",
        left: "12px",

        "&:hover": {
          backgroundColor: colors.white,
        },
      }}
      onClick={clickHandler}
      loading={isWishlistRecordAdding || isWishlistRecordRemoving}
    >
      <Heart />
    </IconButton>
  );
};
