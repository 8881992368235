import React from "react";
import { EntityDetailsGrid } from "../../../../components/EntityDetailsGrid";
import { useTranslation } from "react-i18next";
import { ImageSliderFixedHeight } from "../../../../components/ImageSliderFixedHeight";
import { Typography } from "@mui/material";
import { GetNewSearchRequestItemDetailsResponseDto } from "../../../../slices/new-search-request-items/types";
import { IsApproved } from "../../IsApproved";
import { useTableTools } from "../../../../utils/useContentTools";

type DataProps = {
  params: ReturnType<typeof useTableTools>;
  requestedItem: GetNewSearchRequestItemDetailsResponseDto;
};

export const Data = ({ params, requestedItem }: DataProps) => {
  const { t } = useTranslation("pages");

  return (
    <EntityDetailsGrid
      blocks={[
        [
          {
            label: t("newSearchRequestItems.details.isApproved"),
            view: (
              <IsApproved
                params={params}
                requestedItemId={requestedItem.id}
                checked={requestedItem.isApproved}
                sx={{
                  marginTop: "-7px",
                  marginLeft: "-10px",
                }}
              />
            ),
            xs: 3,
          },
          {
            label: t("newSearchRequestItems.details.quantity"),
            value: JSON.stringify(requestedItem.quantity),
            xs: 9,
          },
        ],
        "divider",
        [
          {
            label: t("newSearchRequestItems.details.school"),
            value: requestedItem.schoolName,
            xs: 12,
          },
          {
            label: t("newSearchRequestItems.details.teacherName"),
            value: `${requestedItem.teacherName} (${requestedItem.teacherTitle})`,
            xs: 6,
          },
          {
            label: t("newSearchRequestItems.details.teacherEmail"),
            value: requestedItem.teacherEmail,
            xs: 6,
          },

          {
            label: t("newSearchRequestItems.details.inquiry"),
            value: requestedItem.inquiryName,
            xs: 6,
          },
          {
            label: t("newSearchRequestItems.details.department"),
            value: requestedItem.departmentName,
            xs: 6,
          },
        ],
        "divider",
        [
          {
            label: t("newSearchRequestItems.details.image"),
            view:
              requestedItem.images.length > 0 ? (
                <ImageSliderFixedHeight
                  images={requestedItem.images.map((url) => ({ src: url }))}
                  height={300}
                  sx={{
                    width: "300px",
                    height: "300px",
                  }}
                />
              ) : (
                <Typography variant="body2" sx={{ marginTop: 0.5 }}>
                  {t("na", { ns: "common" })}
                </Typography>
              ),
            xs: 12,
          },
        ],
      ]}
    />
  );
};
