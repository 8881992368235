import { api } from "../api";
import {
  AddInventoryEntryRequestDto,
  AddInventoryEntryResponseDto,
  EditInventoryEntryRequestDto,
  EditInventoryEntryResponseDto,
  GetInventoryEntriesRequestDto,
  GetInventoryEntriesResponseDto,
  GetInventoryEntryRequestDto,
  GetInventoryEntryResponseDto,
  GetInventoryEntryWithChangesResponseDto,
} from "./types";
import { parseSortModel } from "../../utils/parseSortModel";

const baseUrl = "inventory";

export const inventoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInventoryEntries: builder.query<
      GetInventoryEntriesResponseDto,
      GetInventoryEntriesRequestDto
    >({
      query: ({ page, pageSize, search, sortModel, countriesIds }) => {
        const urlSearchParams = new URLSearchParams({
          page: page.toString(),
          pageSize: pageSize.toString(),
          ...parseSortModel(sortModel),
        });

        if (search && search.length > 0) {
          urlSearchParams.append("search", search);
        }

        if (countriesIds && countriesIds.length > 0) {
          urlSearchParams.append("countriesIds", countriesIds.toString());
        }

        return `${baseUrl}?${urlSearchParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              { type: "Inventory", id: "list" },
              ...result.data.map(({ id }) => ({
                type: "Inventory" as const,
                id,
              })),
            ]
          : [{ type: "Inventory", id: "list" }],
    }),
    getInventoryEntry: builder.query<
      GetInventoryEntryResponseDto,
      GetInventoryEntryRequestDto
    >({
      query: ({ id }) => `${baseUrl}/${id}`,
      providesTags: (_result, _error, { id }) => [{ type: "Inventory", id }],
    }),
    getInventoryEntryWithChangesHistory: builder.query<
      GetInventoryEntryWithChangesResponseDto,
      GetInventoryEntryRequestDto
    >({
      query: ({ id }) => `${baseUrl}/${id}?changes=true`,
      providesTags: (_result, _error, { id }) => [{ type: "Inventory", id }],
    }),
    addInventoryEntry: builder.mutation<
      AddInventoryEntryResponseDto,
      AddInventoryEntryRequestDto
    >({
      query: (body) => ({
        url: `${baseUrl}`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        "Product",
        "Item",
        {
          type: "Inventory",
          id: "list",
        },
      ],
    }),
    editInventoryEntry: builder.mutation<
      EditInventoryEntryResponseDto,
      EditInventoryEntryRequestDto
    >({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        "Product",
        "Item",
        "MatchedProductsList",
        { type: "Inventory", id },
      ],
    }),
  }),
});

export const {
  useGetInventoryEntriesQuery,
  useGetInventoryEntryQuery,
  useGetInventoryEntryWithChangesHistoryQuery,
  useAddInventoryEntryMutation,
  useEditInventoryEntryMutation,
} = inventoryApi;
