import React, { useCallback } from "react";
import { Checkbox, CheckboxProps } from "@mui/material";
import { useChangeNewSearchRequestItemStatusMutation } from "../../slices/new-search-request-items/api";
import { useTableTools } from "../../utils/useContentTools";
import { useMessageHandlers } from "../../utils/useMessageHandlers";

type IsApprovedProps = Omit<CheckboxProps, "onChange"> & {
  params: ReturnType<typeof useTableTools>;
  requestedItemId: number;
};

export const IsApproved = ({
  params: { page, pageSize, sortModel, search: debouncedSearch },
  requestedItemId,
  checked,
  ...props
}: IsApprovedProps) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const [changeStatus, { isLoading: isChanging }] =
    useChangeNewSearchRequestItemStatusMutation();

  const changeHandler = useCallback(() => {
    changeStatus({
      params: {
        page,
        pageSize,
        sortModel,
        search: debouncedSearch,
      },
      requestedItemId,
      isApproved: !checked,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    changeStatus,
    checked,
    debouncedSearch,
    handleError,
    handleSuccess,
    page,
    pageSize,
    requestedItemId,
    sortModel,
  ]);

  return (
    <Checkbox
      onChange={changeHandler}
      disabled={isChanging}
      checked={checked}
      {...props}
    />
  );
};
