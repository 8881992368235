import {
  AddProductRequestDto,
  AddProductResponseDto,
  EditProductRequestDto,
  GetProductPreviewResponseDto,
  GetProductPreviewsRequestDto,
  GetProductRequestDto,
  GetProductResponseDto,
  GetProductsAutocompleteRequestDto,
  GetProductsAutocompleteResponseDto,
  GetProductsRequestDto,
  GetProductsResponseDto,
  GetProductsToAssignRequestDto,
  GetProductsToAssignResponseDto,
} from "./types";
import { MessageResponseDto } from "../../types";
import { api } from "../api";

export const productsBaseUrl = "products";

export const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<GetProductsResponseDto, GetProductsRequestDto>({
      query: ({ page, pageSize, search, supplierIds, activities }) => {
        const params: Record<string, string> = {
          page: JSON.stringify(page),
          pageSize: JSON.stringify(pageSize),
        };

        if (search) {
          params.search = search;
        }

        if (supplierIds && supplierIds.length > 0) {
          params.supplierIds = supplierIds.join(",");
        }

        if (activities && activities.length > 0) {
          params.activities = activities.join(",");
        }

        const query = new URLSearchParams(params);
        return `${productsBaseUrl}?${query.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              { type: "Product", id: "list" },
              ...result.data.map(({ id }) => ({
                type: "Product" as const,
                id,
              })),
            ]
          : [{ type: "Product", id: "list" }],
    }),
    getProductsAutocomplete: builder.query<
      GetProductsAutocompleteResponseDto,
      GetProductsAutocompleteRequestDto
    >({
      query: ({ search }) => {
        const urlSearchParams = new URLSearchParams();

        if (search && search.length > 0) {
          urlSearchParams.append("search", search);
        }

        return `${productsBaseUrl}/autocomplete?${urlSearchParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              { type: "Product", id: "list" },
              ...result.map(({ id }) => ({
                type: "Product" as const,
                id,
              })),
            ]
          : [{ type: "Product", id: "list" }],
    }),
    getProductsToAssign: builder.query<
      GetProductsToAssignResponseDto,
      GetProductsToAssignRequestDto
    >({
      query: ({ itemId, search, suppliersIds }) => {
        const urlSearchParams = new URLSearchParams({
          search,
        });

        if (suppliersIds.length > 0) {
          urlSearchParams.append("suppliersIds", suppliersIds.join(","));
        }

        return `${productsBaseUrl}/to-assign/${itemId}?${urlSearchParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              { type: "Product", id: "list" },
              ...result.map(({ id }) => ({
                type: "Product" as const,
                id,
              })),
            ]
          : [{ type: "Product", id: "list" }],
    }),
    getProduct: builder.query<GetProductResponseDto, GetProductRequestDto>({
      query: ({ id, rawImages }) => {
        const query = rawImages ? `?rawImages=true` : "";

        return `${productsBaseUrl}/${id}${query}`;
      },
      providesTags: (result, error, { id, rawImages }) => [
        { type: "Product", id, rawImages: rawImages ?? false },
      ],
    }),
    getProductPreviews: builder.query<
      GetProductPreviewResponseDto,
      GetProductPreviewsRequestDto
    >({
      query: (id) => `${productsBaseUrl}/${id}/previews`,
      providesTags: (result, error, id) => [{ type: "ProductPreviews", id }],
    }),
    addProduct: builder.mutation<AddProductResponseDto, AddProductRequestDto>({
      query: ({ description, retailPrice, supplierPrice, ...body }) => ({
        url: productsBaseUrl,
        method: "POST",
        body: {
          ...body,
          retailPrice: retailPrice
            ? Math.trunc(Number(retailPrice) * 100)
            : null,
          supplierPrice: supplierPrice
            ? Math.trunc(Number(supplierPrice) * 100)
            : null,
          description: description === "<p></p>" ? null : description,
        },
      }),
      invalidatesTags: [
        { type: "Product", id: "list" },
        { type: "Product", id: "maxPrice" },
      ],
    }),
    editProduct: builder.mutation<MessageResponseDto, EditProductRequestDto>({
      query: ({ id, retailPrice, supplierPrice, description, ...body }) => ({
        url: `${productsBaseUrl}/${id}`,
        method: "PUT",
        body: {
          ...body,
          retailPrice: retailPrice
            ? Math.trunc(Number(retailPrice) * 100)
            : null,
          supplierPrice: supplierPrice
            ? Math.trunc(Number(supplierPrice) * 100)
            : null,
          description: description === "<p></p>" ? null : description,
        },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Product", id },
        { type: "Product", id: "maxPrice" },
        "MatchedProductsList",
      ],
    }),
    changeProductAvailability: builder.mutation<
      MessageResponseDto,
      { id: number; availability: boolean }
    >({
      query: ({ id, ...body }) => ({
        url: `${productsBaseUrl}/${id}/changeAvailability`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Product", id: "list" },
        { type: "Product", id },
        { type: "Product", id: "maxPrice" },
      ],
    }),
    deleteProduct: builder.mutation<MessageResponseDto, number>({
      query: (id) => ({ url: `${productsBaseUrl}/${id}`, method: "DELETE" }),
      invalidatesTags: () => [
        { type: "Product", id: "list" },
        { type: "Product", id: "maxPrice" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddProductMutation,
  useEditProductMutation,
  useGetProductQuery,
  useGetProductPreviewsQuery,
  useGetProductsQuery,
  useGetProductsAutocompleteQuery,
  useGetProductsToAssignQuery,
  useChangeProductAvailabilityMutation,
  useDeleteProductMutation,
} = productsApi;
