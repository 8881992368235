import React, { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useTableUrlTools } from "../../../../utils/useTableUrlTools";
import { Spinner } from "../../../../components/wideDrawer/Spinner";
import { DisplayContainer } from "../../../../components/wideDrawer/DisplayContainer";
import { Data } from "./Data";
import { baseUrl } from "../../baseUrl";
import { useGetNewSearchRequestItemDetailsQuery } from "../../../../slices/new-search-request-items/api";
import { useTableTools } from "../../../../utils/useContentTools";

type DetailsProps = {
  params: ReturnType<typeof useTableTools>;
};

export const Details = ({ params }: DetailsProps) => {
  const { id, closeDrawer } = useTableUrlTools(baseUrl);

  const {
    data: requestedItem,
    isLoading: isRequestedItemLoading,
    error: requestedItemLoadingError,
  } = useGetNewSearchRequestItemDetailsQuery(id ? { itemId: id } : skipToken);

  useEffect(() => {
    if (requestedItemLoadingError) {
      closeDrawer();
    }
  }, [requestedItemLoadingError, closeDrawer]);

  if (isRequestedItemLoading) {
    return <Spinner />;
  }

  if (!requestedItem) {
    return null;
  }

  return (
    <DisplayContainer heading={requestedItem.name}>
      <Data params={params} requestedItem={requestedItem} />
    </DisplayContainer>
  );
};
