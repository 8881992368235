import React from "react";
import { IconButton } from "../../../../components/astraComponents/IconButton";
import { Heart } from "../../../../components/astraIcons/Heart";
import { useSelector } from "../../../../store";
import { colors } from "../../../../theme/astra/colors";
import { selectSelfRole } from "../../../../slices/auth/selectors";

export type WishlistButtonProps = {
  value: boolean;
  onClick: () => void;
};

export const WishlistButton = ({ value, onClick }: WishlistButtonProps) => {
  const selfRole = useSelector(selectSelfRole);

  if (
    !selfRole ||
    !["principal", "headOfDepartment", "teacher"].includes(selfRole)
  ) {
    return null;
  }

  return (
    <IconButton
      variant="transparent"
      sx={{
        color: value ? colors.red : colors.blue,
      }}
      onClick={onClick}
    >
      <Heart />
    </IconButton>
  );
};
