import React, { useCallback } from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetShopProductsResponseDto } from "../../../slices/shop/types";
import { colors } from "../../../theme/astra/colors";
import { useShopCatalogParams } from "../useShopCatalogParams";

type NothingFoundProps = {
  misspelledWords?: GetShopProductsResponseDto["misspelledWords"];
  suggestResettingFilters?: GetShopProductsResponseDto["suggestResettingFilters"];
  resetFilters: ReturnType<typeof useShopCatalogParams>["resetFiltersHandler"];
};

export const NothingFound = ({
  misspelledWords = [],
  suggestResettingFilters,
  resetFilters,
}: NothingFoundProps) => {
  const { t } = useTranslation("pages");

  const resetFiltersClickHandler = useCallback(() => {
    if (suggestResettingFilters) {
      resetFilters();
    }
  }, [resetFilters, suggestResettingFilters]);

  const misspelledWordsElement = misspelledWords.map((word, index) => {
    const isLastWord = misspelledWords.length === index + 1;
    const shouldUseOr = misspelledWords.length === index + 2;

    return (
      <React.Fragment key={word}>
        <Text as="span">{word}</Text>
        {!isLastWord && shouldUseOr && (
          <Text as="span">{t("shopCatalog.or")}</Text>
        )}
        {!isLastWord && !shouldUseOr && <Text as="span">, </Text>}
      </React.Fragment>
    );
  });

  if (suggestResettingFilters && misspelledWordsElement.length > 0) {
    return (
      <Container>
        <Box>
          <Text as="span">{t("shopCatalog.thereAreResultsFor")}</Text>
          {misspelledWordsElement}
          <Text as="span">{t("shopCatalog.ifYouResetFilters")}</Text>
        </Box>
        <Button
          variant="outlined"
          sx={{
            marginTop: "16px",
          }}
          onClick={resetFiltersClickHandler}
        >
          {t("shopCatalog.resetFiltersAndSearch")}
        </Button>
      </Container>
    );
  }

  if (misspelledWordsElement.length > 0) {
    return (
      <Container>
        <Text as="span">{t("shopCatalog.perhapsYouWereLookingFor")}</Text>
        {misspelledWordsElement}
      </Container>
    );
  }

  return (
    <Container>
      <Text as="span">{t("shopCatalog.nothingFound")}</Text>
    </Container>
  );
};

const Container = styled(Box)({
  width: "100%",
  gridColumn: "1 / -1",
  textAlign: "center",
  marginTop: "32px",
  display: "block",
});

const Text = styled(Typography)({
  fontSize: "20px",
  lineHeight: "24px",
  color: colors.darkBlue,
  fontWeight: "500",
});
