import React from "react";
import { Box } from "@mui/material";
import { useSchoolInquiryItemsTools } from "../../../../useSchoolInquiryItemsTools";
import { List } from "./List/List";
import { GetSchoolDepartmentInquiryForHeadResponseDto } from "../../../../../../slices/schoolInquiries/types";
import { NeedReview } from "../../actions/assignment/NeedReview";
import { Approve } from "../../actions/assignment/Approve";
import { ContextMenu } from "../../actions/assignment/ContextMenu";
import { SendToPrincipal } from "../../actions/departmentInquiry/SendToPrincipal";

type ListsProps = {
  departmentInquiry: GetSchoolDepartmentInquiryForHeadResponseDto;
};

export const Lists = ({ departmentInquiry }: ListsProps) => {
  const { rootUrl, inquiryId, departmentInquiryId, assignmentId } =
    useSchoolInquiryItemsTools();

  if (!inquiryId || !departmentInquiryId) {
    return null;
  }

  const departmentInquiryEntry = (
    <List
      type="departmentInquiry"
      to={`${rootUrl}/${departmentInquiryId}/items`}
      focused={!assignmentId}
      status={departmentInquiry.schoolStatus}
      requiresAttention={departmentInquiry.requiresAttention}
      budget={{
        spent: departmentInquiry.budgetSpent,
        assigned: departmentInquiry.budgetAssigned,
        currency: departmentInquiry.budgetCurrency,
        productsWithoutPrice: departmentInquiry.productsWithoutPrice,
        totalProducts: departmentInquiry.totalProducts,
      }}
      schoolTitles={departmentInquiry.schoolTitles}
      actions={
        <Box
          sx={{
            paddingRight: "60px",
          }}
        >
          <SendToPrincipal
            {...{
              inquiryId,
              departmentInquiryId,
            }}
            disabled={
              !departmentInquiry.possibleActions.includes("sendToPrincipal")
            }
            displayHelp={departmentInquiry.displayHelpMessageForActions.includes(
              "sendToPrincipal"
            )}
            principalTitle={departmentInquiry.schoolTitles.principal}
          />
        </Box>
      }
    />
  );

  const assignmentsEntries = departmentInquiry.assignments.map((assignment) => (
    <List
      key={assignment.id}
      to={`${rootUrl}/${departmentInquiryId}/assignments/${assignment.id}`}
      focused={assignment.id === assignmentId}
      type="assignment"
      status={assignment.schoolStatus}
      requiresAttention={assignment.requiresAttention}
      user={assignment.fullName}
      schoolTitles={assignment.schoolTitles}
      role={assignment.role}
      budget={{
        spent: assignment.budgetSpent,
        assigned: assignment.budgetAssigned,
        currency: assignment.budgetCurrency,
        productsWithoutPrice: assignment.productsWithoutPrice,
        totalProducts: assignment.totalProducts,
      }}
      actions={
        <>
          <NeedReview
            {...{
              inquiryId,
              departmentInquiryId,
              assignmentId: assignment.id,
            }}
            disabled={!assignment.possibleActions.includes("sendToReview")}
            ownAssignment={assignment.role === "headOfDepartment"}
          />
          <Approve
            {...{
              inquiryId,
              departmentInquiryId,
              assignmentId: assignment.id,
            }}
            disabled={!assignment.possibleActions.includes("approve")}
            displayHelp={assignment.displayHelpMessageForActions.includes(
              "approve"
            )}
          />
          <ContextMenu
            {...{
              inquiryId,
              departmentInquiryId,
              assignmentId: assignment.id,
            }}
            editAction={{
              disabled: !assignment.possibleActions.includes("edit"),
            }}
            cancelAction={{
              disabled: !assignment.possibleActions.includes("cancel"),
            }}
            deleteAction={{
              disabled: !assignment.possibleActions.includes("delete"),
            }}
          />
        </>
      }
    />
  ));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: "10px",
        marginTop: "13px",
      }}
    >
      {departmentInquiryEntry}
      {assignmentsEntries}
    </Box>
  );
};
