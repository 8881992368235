import { api, coreApi } from "../api";
import {
  AddWishlistRecordRequestDto,
  AddWishlistRecordResponseDto,
  RemoveWishlistRecordRequestDto,
  RemoveWishlistRecordResponseDto,
} from "./types";

const baseUrl = "wishlist-records";

export const wishlistRecordsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addWishlistRecord: builder.mutation<
      AddWishlistRecordResponseDto,
      AddWishlistRecordRequestDto
    >({
      query: ({ productId }) => ({
        url: `${baseUrl}/${productId}`,
        method: "POST",
      }),
      invalidatesTags: (_result, _error, { params }) =>
        params ? [] : [{ type: "Product", id: "list" }],
      onQueryStarted: async (
        { productId, params },
        { dispatch, queryFulfilled }
      ) => {
        try {
          await queryFulfilled;

          if (params) {
            dispatch(
              coreApi.util.updateQueryData(
                "getShopProducts",
                params,
                (draft) => ({
                  ...draft,
                  data: draft.data.map((product) => ({
                    ...product,
                    isInWishlist:
                      product.id === productId ? true : product.isInWishlist,
                  })),
                })
              )
            );
          }

          dispatch(
            coreApi.util.updateQueryData(
              "getShopProduct",
              productId,
              (draft) => ({
                ...draft,
                isInWishlist: true,
              })
            )
          );
        } catch {
          /* empty */
        }
      },
    }),
    removeWishlistRecord: builder.mutation<
      RemoveWishlistRecordResponseDto,
      RemoveWishlistRecordRequestDto
    >({
      query: ({ productId }) => ({
        url: `${baseUrl}/${productId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { params }) =>
        params ? [] : [{ type: "Product", id: "list" }],
      onQueryStarted: async (
        { productId, params },
        { dispatch, queryFulfilled }
      ) => {
        try {
          await queryFulfilled;

          if (params) {
            dispatch(
              coreApi.util.updateQueryData(
                "getShopProducts",
                params,
                (draft) => ({
                  ...draft,
                  data: draft.data.map((product) => ({
                    ...product,
                    isInWishlist:
                      product.id === productId ? false : product.isInWishlist,
                  })),
                })
              )
            );
          }

          dispatch(
            coreApi.util.updateQueryData(
              "getShopProduct",
              productId,
              (draft) => ({
                ...draft,
                isInWishlist: false,
              })
            )
          );
        } catch {
          /* empty */
        }
      },
    }),
  }),
});

export const { useAddWishlistRecordMutation, useRemoveWishlistRecordMutation } =
  wishlistRecordsApi;
