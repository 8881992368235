import React, { useCallback, useMemo, useRef } from "react";
import { Box, styled } from "@mui/material";
import { Product } from "./Product/Product";
import { Pagination } from "./Pagination";
import {
  GetShopProductsRequestDto,
  GetShopProductsResponseDto,
} from "../../../slices/shop/types";
import { ProductSkeleton } from "./ProductSkeleton";
import { SHOP_PAGE_SIZE } from "../consts";
import { NothingFound } from "./NothingFound";
import { useShopCatalogParams } from "../useShopCatalogParams";

export type ContentProps = {
  products?: GetShopProductsResponseDto["data"];
  isLoading?: boolean;
  isFetching?: boolean;
  count?: number;
  page: number;
  onPageChange: (page: number) => void;
  misspelledWords?: GetShopProductsResponseDto["misspelledWords"];
  suggestResettingFilters?: GetShopProductsResponseDto["suggestResettingFilters"];
  resetFilters: ReturnType<typeof useShopCatalogParams>["resetFiltersHandler"];
  params: GetShopProductsRequestDto;
};

export const Content = ({
  isLoading,
  isFetching,
  products,
  count,
  page,
  onPageChange,
  misspelledWords,
  suggestResettingFilters,
  resetFilters,
  params,
}: ContentProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const pageChangeHandler = useCallback(
    (newPage: number) => {
      if (containerRef.current && newPage !== page) {
        window.scrollTo({
          top: containerRef.current.offsetTop - 150,
        });
      }

      onPageChange(newPage);
    },
    [onPageChange, page]
  );

  const areSkeletonsShowing = isLoading || isFetching;

  const skeletons = useMemo(() => {
    if (!areSkeletonsShowing) {
      return null;
    }

    return Array.from(new Array(SHOP_PAGE_SIZE)).map((_value, index) => (
      <ProductSkeleton key={index} />
    ));
  }, [areSkeletonsShowing]);

  const content = useMemo(() => {
    if (!products || areSkeletonsShowing) {
      return null;
    }

    if (products.length === 0) {
      return (
        <NothingFound
          misspelledWords={misspelledWords}
          suggestResettingFilters={suggestResettingFilters}
          resetFilters={resetFilters}
        />
      );
    }

    return products.map((product) => (
      <Product key={product.id} product={product} params={params} />
    ));
  }, [
    areSkeletonsShowing,
    misspelledWords,
    params,
    products,
    resetFilters,
    suggestResettingFilters,
  ]);

  return (
    <>
      <ContainerBox ref={containerRef}>
        {skeletons}
        {content}
      </ContainerBox>
      <Pagination count={count} page={page} onChange={pageChangeHandler} />
    </>
  );
};

const ContainerBox = styled(Box)(({ theme }) => ({
  marginTop: "32px",
  display: "grid",
  columnGap: "64px",
  rowGap: "33px",
  gridTemplateColumns: "repeat(4, 1fr)",

  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(5, 1fr)",
  },
}));
